<template>
    <div class="h-min-content w-full">
        <ComplaintsSamplesTable />
    </div>
</template>

<script>
import ComplaintsSamplesTable from '@/components/Dashboard/Complaints/Samples/ComplaintsSamplesTable.vue'
export default {
  components: {
    ComplaintsSamplesTable
  }
}
</script>

<style>

</style>